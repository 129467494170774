// Documentation is located https://github.com/kidjp85/react-id-swiper/tree/1.6.4

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';

class MicCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      isLastClicked: false,
      isFirstClicked: false
    };
    this.swiperResize = this.swiperResize.bind(this);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.swiperResize);
    setTimeout(() => {
      if (this.swiper) {
        this.swiper.update();
      }
    }, 400);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.swiperResize);
  }

  swiperResize() {
    if (this.swiper) {
      this.swiper.update();
    }
  }

  goNext(e) {
    const { posts } = this.props;
    const { isLastClicked } = this.state;
    if (this.swiper) {
      this.swiper.autoplay.pause();
      e.preventDefault();
      if (this.swiper.activeIndex === posts.length - 1) {
        if (isLastClicked) {
          this.swiper.slideTo(0);
          this.setState({ isLastClicked: false });
        } else {
          this.setState({ isLastClicked: true });
          this.swiper.slideNext();
        }
      } else {
        this.swiper.slideNext();
      }
    }
  }

  goPrev(e) {
    const { posts } = this.props;
    const { isFirstClicked } = this.state;
    if (this.swiper) {
      this.swiper.autoplay.pause();
      e.preventDefault();
      if (this.swiper.activeIndex === 0) {
        if (isFirstClicked) {
          this.swiper.slideTo(posts.length - 1);
          this.setState({ isFirstClicked: false });
        } else {
          this.setState({ isFirstClicked: true });
          this.swiper.slidePrev();
        }
      } else {
        this.swiper.slidePrev();
      }
    }
  }

  render() {
    const { activeIndex } = this.state;
    const { posts, isMobile = false } = this.props;

    let settings = {
      effect: 'fade',
      navigation: {
        nextEl: '.carousel-next-btn',
        prevEl: '.carousel-prev-btn'
      },
      centeredSlides: true,
      autoplay: {
        delay: 8000,
        disableOnInteraction: true
      },
      loop: true,
      renderPrevButton: () => (
        <button className="carousel-prev-btn" onClick={this.goPrev}>
          <img
            height={14}
            alt="Previous"
            src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:dc='https://purl.org/dc/elements/1.1/' xmlns:cc='https://creativecommons.org/ns' xmlns:rdf='https://www.w3.org/1999/02/22-rdf-syntax-ns' xmlns:svg='https://www.w3.org/2000/svg' xmlns='https://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'%3E%3Cpath fill='%23ffffff' style='fill-rule:evenodd;stroke:none;stroke-width:1' d='M 9.2929348,2.707148 1.4150004,10.586 1.0000004,11 0.70710722,11.292893 c -0.39052429,0.390524 -0.39052429,1.02369 0,1.414214 L 1.0000004,13 l 0.415,0.414 7.8781432,7.878643 c 0.39042,0.390466 1.0234234,0.390596 1.4140044,2.91e-4 l 7.45e-4,1.73e-4 c 0.390662,-0.390386 0.390886,-1.023551 5e-4,-1.414214 -8.3e-5,-8.3e-5 -1.67e-4,-1.66e-4 -2.5e-4,-2.5e-4 L 3.8290004,13 H 23 c 0.552285,0 1,-0.447715 1,-1 v 0 c 0,-0.552285 -0.447715,-1 -1,-1 H 3.8290004 L 10.708143,4.1213567 c 0.390524,-0.3905243 0.390524,-1.0236893 0,-1.4142136 -8.3e-5,-8.33e-5 -1.67e-4,-1.666e-4 -2.5e-4,-2.499e-4 l -7.45e-4,1.725e-4 C 10.316567,2.3167609 9.6835636,2.3168912 9.2931436,2.7073568 Z' /%3E%3C/svg%3E%0A"
          />
        </button>
      ),
      renderNextButton: () => (
        <button className="carousel-next-btn" onClick={this.goNext}>
          <img
            height={14}
            alt="Next"
            src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:dc='https://purl.org/dc/elements/1.1/' xmlns:cc='https://creativecommons.org/ns' xmlns:rdf='https://www.w3.org/1999/02/22-rdf-syntax-ns' xmlns:svg='https://www.w3.org/2000/svg' xmlns='https://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'%3E%3Cpath fill='%23ffffff' style='fill-rule:evenodd;stroke:none;stroke-width:1' d='M 14.707066,2.707148 22.585,10.586 23,11 l 0.292893,0.292893 c 0.390525,0.390524 0.390525,1.02369 0,1.414214 L 23,13 l -0.415,0.414 -7.878143,7.878643 c -0.39042,0.390466 -1.023424,0.390596 -1.414005,2.91e-4 l -7.45e-4,1.73e-4 c -0.390662,-0.390386 -0.390886,-1.023551 -5e-4,-1.414214 8.3e-5,-8.3e-5 1.67e-4,-1.66e-4 2.5e-4,-2.5e-4 L 20.171,13 H 1 C 0.447715,13 0,12.552285 0,12 v 0 C 0,11.447715 0.447715,11 1,11 H 20.171 L 13.291857,4.1213567 c -0.390524,-0.3905243 -0.390524,-1.0236893 0,-1.4142136 8.3e-5,-8.33e-5 1.67e-4,-1.666e-4 2.5e-4,-2.499e-4 l 7.45e-4,1.725e-4 c 0.390581,-0.3903048 1.023585,-0.3901745 1.414005,2.911e-4 z' /%3E%3C/svg%3E%0A"
          />
        </button>
      )
    };

    if (isMobile) {
      settings = {
        navigation: {
          nextEl: '.carousel-next-btn-mobile',
          prevEl: '.carousel-prev-btn-mobile'
        },
        centeredSlides: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: true
        }
      };

      return (
        <div>
          <Swiper
            {...settings}
            ref={node => {
              if (node) this.swiper = node.swiper;
            }}
          >
            {posts.map(({ headline, description, image, url }, index) => (
              <article key={url + index} style={{ padding: 10 }}>
                <img width="100%" src={image} alt={headline} />
                <div className="content">
                  <h1 className="carousel-title">{headline}</h1>
                  <p className="carousel-description">{description}</p>
                  <a className="carousel-read-more" alt={headline} href={url}>
                    READ MORE
                  </a>
                </div>
              </article>
            ))}
          </Swiper>
          {(isMobile && (
            <div className="carousel-next-prev-mobile-container">
              <button
                className="carousel-prev-btn-mobile"
                onClick={this.goPrev}
              >
                <img
                  height={14}
                  alt="Previous"
                  src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:dc='https://purl.org/dc/elements/1.1/' xmlns:cc='https://creativecommons.org/ns' xmlns:rdf='https://www.w3.org/1999/02/22-rdf-syntax-ns' xmlns:svg='https://www.w3.org/2000/svg' xmlns='https://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'%3E%3Cpath fill='%23ffffff' style='fill-rule:evenodd;stroke:none;stroke-width:1' d='M 9.2929348,2.707148 1.4150004,10.586 1.0000004,11 0.70710722,11.292893 c -0.39052429,0.390524 -0.39052429,1.02369 0,1.414214 L 1.0000004,13 l 0.415,0.414 7.8781432,7.878643 c 0.39042,0.390466 1.0234234,0.390596 1.4140044,2.91e-4 l 7.45e-4,1.73e-4 c 0.390662,-0.390386 0.390886,-1.023551 5e-4,-1.414214 -8.3e-5,-8.3e-5 -1.67e-4,-1.66e-4 -2.5e-4,-2.5e-4 L 3.8290004,13 H 23 c 0.552285,0 1,-0.447715 1,-1 v 0 c 0,-0.552285 -0.447715,-1 -1,-1 H 3.8290004 L 10.708143,4.1213567 c 0.390524,-0.3905243 0.390524,-1.0236893 0,-1.4142136 -8.3e-5,-8.33e-5 -1.67e-4,-1.666e-4 -2.5e-4,-2.499e-4 l -7.45e-4,1.725e-4 C 10.316567,2.3167609 9.6835636,2.3168912 9.2931436,2.7073568 Z' /%3E%3C/svg%3E%0A"
                />
              </button>
              <button
                className="carousel-next-btn-mobile"
                onClick={this.goNext}
              >
                <img
                  height={14}
                  alt="Next"
                  src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg xmlns:dc='https://purl.org/dc/elements/1.1/' xmlns:cc='https://creativecommons.org/ns' xmlns:rdf='https://www.w3.org/1999/02/22-rdf-syntax-ns' xmlns:svg='https://www.w3.org/2000/svg' xmlns='https://www.w3.org/2000/svg' width='24px' height='24px' viewBox='0 0 24 24' version='1.1'%3E%3Cpath fill='%23ffffff' style='fill-rule:evenodd;stroke:none;stroke-width:1' d='M 14.707066,2.707148 22.585,10.586 23,11 l 0.292893,0.292893 c 0.390525,0.390524 0.390525,1.02369 0,1.414214 L 23,13 l -0.415,0.414 -7.878143,7.878643 c -0.39042,0.390466 -1.023424,0.390596 -1.414005,2.91e-4 l -7.45e-4,1.73e-4 c -0.390662,-0.390386 -0.390886,-1.023551 -5e-4,-1.414214 8.3e-5,-8.3e-5 1.67e-4,-1.66e-4 2.5e-4,-2.5e-4 L 20.171,13 H 1 C 0.447715,13 0,12.552285 0,12 v 0 C 0,11.447715 0.447715,11 1,11 H 20.171 L 13.291857,4.1213567 c -0.390524,-0.3905243 -0.390524,-1.0236893 0,-1.4142136 8.3e-5,-8.33e-5 1.67e-4,-1.666e-4 2.5e-4,-2.499e-4 l 7.45e-4,1.725e-4 c 0.390581,-0.3903048 1.023585,-0.3901745 1.414005,2.911e-4 z' /%3E%3C/svg%3E%0A"
                />
              </button>
            </div>
          )) ||
            null}
        </div>
      );
    }

    return (
      <div className="fc-swiper-slideshow">
        <Swiper
          {...settings}
          ref={node => {
            if (node) this.swiper = node.swiper;
          }}
        >
          {posts.map(({ headline, description, image, url }, index) => (
            <article
              key={url + index}
              className={`fc-swiper-slideshow__article ${
                activeIndex === index
                  ? 'fc-swiper-slideshow__article--active'
                  : ''
              }`}
            >
              <div style={{ display: 'flex' }}>
                <img width="300px" height="300px" src={image} alt={headline} />
                <div style={{ display: 'flex', height: 200, marginLeft: 45 }}>
                  <div
                    className="content"
                    style={{ display: 'inline-block', alignSelf: 'flex-end' }}
                  >
                    <h1 className="carousel-title">{headline}</h1>
                    <p className="carousel-description">{description}</p>
                    <a
                      className="carousel-read-more"
                      alt={headline}
                      href={url}
                      target="_blank"
                    >
                      READ MORE
                    </a>
                  </div>
                </div>
              </div>
            </article>
          ))}
        </Swiper>
      </div>
    );
  }
}

MicCarousel.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default MicCarousel;
